@font-face {
     font-family: "Cookie";
     src: url("../fonts/Cookie-Regular.ttf") format('truetype'),
}

// Custom style for bootstrap classes
body {
  font-size: .9375rem;
  color: #2c3e50;
}

.navbar-dark {
  min-height: 60px;

  .navbar-toggler {
    margin-right: 15px;
  }

  .navbar-nav {
    .active > .nav-link,
    .nav-link.active,
    .nav-link.show,
    .show > .nav-link {
      background-color: #1a242f;
    }

    .nav-link {
      color: #fff;
      padding: 10px 15px;

      &:focus, &:hover {
        color: #18bc9c;
      }
    }
  }
}

.bg-darkblue {
  background-color: #2c3e50 !important;
}

.btn-darkblue {
  color: #fff;
  background-color: #2c3e50;
  border-color: #2c3e50;

  &:hover {
    color: #fff;
    background-color: #1e2b37;
    border-color: #1a252f;
  }

}

.btn-success {
  color: #fff;
  background-color: #18BC9C;
  border-color: #18BC9C;

  &:hover {
    color: #fff;
    background-color: #149a80;
    border-color: #128f76;
  }
}

.btn-info {
  color: #fff;
  background-color: #3498db;
  border-color: #3498db;

  &:hover {
    color: #fff;
    background-color: #2384c6;
    border-color: #217dbb;
  }
}

.dropdown-menu {
  font-size: .9375rem;

  > .active {
    > a {
      color: #fff;
      text-decoration: none;
      outline: 0;
      background-color: #2c3e50;

      &:hover, &:focus {
        color: #fff;
        text-decoration: none;
        outline: 0;
        background-color: #2c3e50;
      }
    }
  }
}

.dropdown-item {
  color: #7b8a8b;

  &:focus, &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #2c3e50;
  }

}

h4, .h4 {
  font-size: 19px;
  font-weight: 400;
}

h5, .h5 {
  font-size: 1.171875rem;
}

.pagination {

  .page-item {
    .page-link {
      color: #fff;
      background-color: #18bc9c;
      border: 0 solid transparent;

      &:hover {
        color: #fff;
        background-color: #0f7864;
        border-color: transparent;
      }
    }
    &.active .page-link {
      color: #fff;
      background-color: #0f7864;
      border-color: transparent;
    }
  }
}

@media (min-width: 768px) {
  .navbar-dark {
    // navbar doesn't fit the screen on smaller devices
    flex-wrap: wrap;
  }
  .navbar-expand-md .navbar-nav {
    flex-wrap: wrap;
    white-space: nowrap;

    .nav-item {
      .nav-link {
        padding: 19.5px 15px;
      }
    }
  }
}

.djangocms-googlemap {
  width: 100%;
}

// end bootstrap modifications

.navbar{
  margin-bottom:0;
  border:none;
}

.navbar-brand .glyphicon{
  margin-right:6px;
}

.hero{
  background:url(../../assets/img/city_bg.jpg) no-repeat center;
  background-size:cover;
  border:none;
}

@media (min-width:992px) {
  .hero .get-it{
    text-align:center;
    margin-top:80px;
    padding-right:30px;
  }
}

@media (max-width:992px) {
  .hero .get-it{
    text-align:center;
  }
}

@media (max-width:992px) {
  .hero .phone-preview{
    text-align:center;
  }
}

.hero .get-it h1, .hero .get-it p{
  color:#fff;
  text-shadow:2px 2px 3px rgba(0,0,0,0.3);
  margin-bottom:40px;
}

.hero .get-it .btn{
  margin-left:10px;
  margin-bottom:10px;
  text-shadow:none;
}

div.iphone-mockup{
  position:relative;
  max-width:250px;
  margin:20px;
  display:inline-block;
}

.iphone-mockup img.device{
  width:100%;
  height:auto;
}

.iphone-mockup .screen{
  position:absolute;
  width:88%;
  height:77%;
  top:12%;
  border-radius:2px;
  left:6%;
  border:1px solid #444;
  background-color:#aaa;
  overflow:hidden;
  background:url(../../assets/img/screen-content-iphone-6.jpg);
  background-size:cover;
  background-position:center;
}

.iphone-mockup .screen:before{
  content:'';
  background-color:#fff;
  position:absolute;
  width:70%;
  height:140%;
  top:-12%;
  right:-60%;
  transform:rotate(-19deg);
  opacity:0.2;
}

.icon-feature{
  text-align:center;
}

.icon-feature .glyphicon{
  font-size:60px;
}

section.features{
  background-color:#369;
  padding:40px 0;
  color:#fff;
}

.features h2{
  color:#fff;
}

.features .icon-features{
  margin-top:15px;
}

.testimonials blockquote{
  text-align:center;
}

section.testimonials{
  margin:50px 0;
}

.site-footer{
  padding:20px 0;
  text-align:center;
}

@media (min-width:768px) {
  .site-footer h5{
    text-align:left;
  }
}

.site-footer h5{
  color:inherit;
  font-size:20px;
}

.site-footer .social-icons a:hover{
  opacity:1;
}

.site-footer .social-icons a{
  display:inline-block;
  width:32px;
  border:none;
  font-size:20px;
  border-radius:50%;
  margin:4px;
  color:#fff;
  text-align:center;
  background-color:#798FA5;
  height:32px;
  opacity:0.8;
  line-height:32px;
}

@media (min-width:768px) {
  .site-footer .social-icons{
    text-align:right;
  }
}

nav.navbar.navbar-default.navbar-static-top{
  border-bottom:4px solid rgb(74,137,190);
}

section.page-header-custom{
  background-color:#4a89be;
  border-bottom:5px solid #ddd;
  margin:0 0 60px 0;
  min-height:50px;
  padding:20px 0;
  position:relative;
  text-align:left;
}

@media screen and (max-width: 768px) { section.page-header-custom { margin:0 0 20px 0;} }

ol.breadcrumb{
  border:none;
  background-color:#4a89be;
  margin:0;
  padding:0;
}

.breadcrumb>.lang:before {
  content: "";
}

li.lang { display: inline-flex; }
.lang > a { margin-left:  5px; }

a{
  color:#75caeb;
}

h2.hv{
  border-bottom:5px;
  border-bottom-color:#18bc9c;
  border-bottom-style:solid;
  color:#fff;
  display:inline-block;
  font-size:32px;
  font-weight:200;
  line-height:32px;
  margin:0 0 -25px;
  padding:0 0 25px;
  position:relative;
}

hr{
  border:0;
  height:1px;
  background-image:linear-gradient(to right, transparent, #666, transparent);
}

article.law-item{
  padding:10px 15px;
  margin:0 0 20px 0;
  border-radius:5px;
  background:whitesmoke;
  border-bottom:4px solid #f5f5f5;
  overflow:auto;
}

section.home-screen{
  width:100vw;
  height:100vh;
}

a.navbar-brand.navbar-link,
.brand {
  font:normal 36px Cookie, cursive;
  padding:10px;
  line-height: 1;
}
.brand.bigger { font-size: 1.4em; }

.banner-area {
  background:url(../../assets/img/city_bg.jpg) no-repeat center center/cover;
  height:calc(100vh - 64px);
}

.relative{
  position:relative;
}

.overlay .overlay-bg{
  background:rgba(132, 96, 246, 0.8);
}

.overlay{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
}

.align-items-center{
  align-items:center !important;
}

.justify-content-center{
  justify-content:center !important;
}

.fullscreen{
  display:flex;
  height:calc(100vh - 64px);
}

.txt-bckground{
  border-radius:15px;
  padding:3em;
  background-color:rgba(255,255,255,0.25);
}

div.divider-small{
  height:25px;
}


/* add Fontawesome plus/minus icon to accordion */
.panel-title a {
  display: block;
}

.panel-title > a:before {
  content:"\f056";
  float: right !important;
  font-family: FontAwesome;
  padding: 0 5px;
}

.panel-title > a.collapsed:before {
    float: right !important;
    content:"\f055";
}
.panel-title > a:hover,
.panel-title > a:active,
.panel-title > a:focus  {
    text-decoration:none;
}

/* END: add Fontawesome plus/minus icon to accordion */

/* make responsive the organigram */
figure img {
    width: 100%;
    height: auto;
    padding: 3em;
}

@media screen and (max-width: 768px) {
  figure img { padding: 0; }
}

.table-doubleheader {
    th,
    tr:first-child td {
       font-weight: bold;
       color: white;
       background: #2c3e50;
       text-align: center;
    }
}

label { margin-top: 15px; }

.copyright {
    position: absolute;
    top: 30px;
    right: 10px;
    color: gray;
}

article.comunicate.summary {
    // background: #ecf0f1;
    padding: 15px 10px;
    border: 1px solid #ecf0f1;
    border-top: 0px;

    p {
        margin: 0;
        font-size: 0.8em;
    }
}


ul.nav-tabs {
  li > a  { background-color: #ecf0f1;}
  li.active > a { background-color: white;}
}

#map {
  width: 100%;
  height: 80vh;
}

// customize django-cms Filer-file plugin
.file {
    display: block;
}

// customize Aldryn Newsblog
ul.category-item,
ul.archive-item {
    list-style-type: none;
    margin-bottom: 30px;

    li {
      margin: 0;
      padding: 0;
      display: block;
      position: relative;

      a {
        font-size: 0.9em;
        padding: 8px 0 8px 18px;
        -webkit-transition: background 0.1s;
        -moz-transition: background 0.1s;
        transition: background 0.1s;
        border-bottom: 1px solid #EDEDDE;
        display: block;
        text-decoration: none;
        line-height: 20px;
      }

      a:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid #333;
        margin: 7px 0 0 -9px;
        position: absolute;
      }
    }
}

// customize endless pagination
.el-pagination {
  & .endless_page_current, .endless_page_link {
    position: relative;
    float: left;
    padding: 10px 15px;
    text-decoration: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #18bc9c;

    &:hover, &:focus {
      color: #ffffff;
      background-color: #0f7864;
    }
  }
  & .endless_page_current {
    color: #ffffff;
    background-color: #0f7864;
  }
  & span:first-child, a:first-child {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  & span:last-child, a:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

}

.card {
    margin-bottom: .5rem;
    border-color: #ecf0f1;
}

.card-header {
    padding: .4rem .9rem;
    background-color: #ecf0f1;
    border-color: #ecf0f1;
    > p {
        padding: 0;
        margin: 0;
        font-size: 17px;

        &:hover {
          color: #18bc9c;
        }

        &:before {
          font-family: FontAwesome;
          float: right !important;
          content: "\f055";
        }
    }
}

// Change + to - on collapse
.card {
  > div[aria-expanded="true"] {
    .card-header {
      > p {
        &:before{
          font-family: FontAwesome;
          float: right !important;
          content: "\f056";
        }
      }
    }
  }
}
