@import url('https://fonts.googleapis.com/css?family=Kalam:700');

.multi-bg {
    background-image: url('../images/bg_pattern.png'), url('../images/landing_img.jpg');
    background-position: left, left;
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    min-height: 100vh;
}

.home-header {
    min-height: 10vh;
    padding-top: 50px;
    padding-bottom: 50px;
}

.home-carousel {
    min-height: calc(50vh - 30px);
    display: flex;
    align-items: center;
    padding: 15px 0;
}

.home-enter {
    padding: 6em 0 0 0;
    text-align: center;

    .btn-custom {
        background-color: rgba(255,255,255,.1);
        border: 2px solid #f1f1f1;
        color: #fff;
        display: inline-block;
        font-size: 15px;
        margin: 0 auto 30px;
        text-transform: uppercase;
    }

    .btn-custom:hover,
    .btn-custom:active,
    .btn-custom:focus {
        background-color: rgba(255,255,255,.5);
    }
}


h1 {
    color: #fff;
    font-size: 5em;
}

h2 {
    font-size: 2.5em;
    // color: #75caeb;
    color: #fff;
    margin-bottom: 30px;
    text-shadow: 1px 1px 1px rgba(0,0,0,.5);
}

.carousel {
    .carousel-indicators {
        bottom: 0;
    }
}
.carousel .carousel-item {
    position: relative;
    text-align: center;

    img {
        margin: 0 auto;
        width: 100%;
    }

    .carousel-caption {
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
    }

    h3 {
        position: absolute;
        bottom: 5px;
        color: white;
        text-align: center;
        width: 100%;
        font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-weight: 400;
        line-height: 1.1;
        background: rgba(0,0,0,.5);
        padding: 15px;
        border-top: 1px solid #18bc9c;
        border-bottom: 1px solid #18bc9c;
    }
}

.slogen {
    text-shadow: 2px 2px #000;
    font-family: 'Kalam', cursive;
    font-weight: 800;
    color: #38e4c1;
}

@media screen and (max-width: 768px) {
    h1 { font-size: 3em; }
    h2 { font-size: 2em; }
    h3 { font-size: 1.2em; }
    .home-header { padding-top: 30px; padding-bottom: 30px; }
}

@media screen and (max-width: 480px) {
    h1 { font-size: 2em; }
    h2 { font-size: 1.5em; }
}
