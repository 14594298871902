footer{
  background-color:#2c3e50;
  box-sizing:border-box;
  width:100%;
  text-align:left;
  padding:30px 60px;
  margin-top:80px;
  bottom:0;
  border-top:4px;
  border-top-color:#4a89be;
  border-top-style:solid;
  color:white;
  font-weight:200;
}

.footer-contacts p{
  display:inline-block;
  color:#ffffff;
  vertical-align:middle;
}

.fa.footer-contacts-icon{
  background-color:#4a89be;
  color:#fff;
  font-size:18px;
  width:32px;
  height:32px;
  border-radius:50%;
  text-align:center;
  line-height:32px;
  margin:10px 10px 10px 0;
}

span.new-line-span{
  display:block;
  font-weight:normal;
  font-size:14px;
  line-height:2;
}

a.brand{
  font:normal 36px Cookie, cursive;
  color:#ffffff;
  line-height:0;
  text-decoration:none;
}

a:hover{
  text-decoration:none;
  color: #18bc9c;
}

a.brand:hover{
  color:#18bc9c;
}

div.divider{
  height:80px;
}

@media (max-width:480px) {
  footer{
    padding:30px 25px;
  }
}

